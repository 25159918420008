//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import LazyHydrate from 'vue-lazy-hydration'
import {
  getReviews, getLocations, getFlotillas
} from '~/utils/server'
import { CACHE_SETTINGS } from '~/utils/cache'
import pageMeta, { mxGetMeta } from '~/mixins/pageMeta'
import VRating from '~/components/v/rating'
import sectionIndexMain from '~/components/section/index/main.vue'
import sectionRecommendedLocations from '~/components/section/index/recommended-locations.vue'
import sectionIndex from '~/components/section/index/section.vue'
import ContactUs from '~/components/section/contact-us'
import OffersInMailbox from '~/components/section/offers-in-mailbox'
import { getFlag } from '~/utils/languages'
import TransitionExpand from '~/components/transition/transition-expand'
import { asyncDataCatch, asyncDataStart } from '~/utils/asyncData'
import TheMostPopularHome from '~/components/section/selection/the-most-popular-home'
import bnrSpring from '~/components/banners/bnrSpring.vue'
import RecentSearches from '~/components/section/selection/recent-searches'
import BnrFlotilla from '~/components/banners/bnrFlotilla'
import TheMostPopular from '~/components/section/selection/the-most-popular'
import HomeBannersSlider from '~/components/section/selection/home-banners-slider'

export default {
  name: 'IndexPage',
  components: {
    LazyHydrate,
    HomeBannersSlider,
    TheMostPopular,
    BnrFlotilla,
    RecentSearches,
    TheMostPopularHome,
    TransitionExpand,
    OffersInMailbox,
    ContactUs,
    sectionIndexMain,
    sectionRecommendedLocations,
    sectionIndex,
    VRating,
    bnrSpring
  },
  filters: {
    randomOrder (items) {
      if (!items) {
        return items
      }
      items = items.slice(0, 12)

      return items.reduce((p, n) => {
        const size = 12
        const index = Math.trunc(Math.random() * (size - 1))
        p.splice(index, 0, n)
        return p
      }, [])
    }
  },
  mixins: [pageMeta],
  async asyncData (ctx) {
    await asyncDataStart(ctx)

    try {
      const ietfLocale = ctx.store.getters['lang/currentLanguage']?.ietf_locale
      const lang = ctx.store.getters['lang/locale']
      const ietf = ietfLocale || 'en-gb'

      await mxGetMeta(ctx, {
        meta: {
          method: 'static',
          breadcrumbs: false
        }
      })

      const flotillas = await ctx.$cacheFetch({
        key: `pIndex-GetFlotillas?lang=${lang}&ietf=${ietf}`, expire: CACHE_SETTINGS.baseTime
      }, async () => {
        return await getFlotillas(ctx, { lang, ietf })
      })
      const simpleLocation = await ctx.$cacheFetch({
        key: `pIndex-getLocationsHome?locale=${lang}&limit=${23}`, expire: CACHE_SETTINGS.baseTime
      }, async () => {
        return await getLocations(ctx, {
          lang,
          ietf,
          locationType: [
            { id: 'region', key: 'region' },
            { id: 'country' },
            { id: 'city' },
            { id: 'marina' },
            { id: 'popular' }
          ],
          locationLimit: {
            default: 23,
            popular: 10
          }
        })
      })
      const reviews = await ctx.$cacheFetch({
        key: `pIndex-getReviews-locale=${lang}`,
        expire: CACHE_SETTINGS.baseTime
      }, async () => {
        return await getReviews(ctx, lang)
      })

      return {
        flotillas,
        reviews,
        simpleLocation
      }
    } catch (e) {
      return asyncDataCatch(ctx, e)
    }
  },
  data () {
    return {
      hover: {
        flotilla: false,
        bareboat: false,
        skippered: false,
        sh: false,
        'review.0': false,
        'review.1': false,
        'review.2': false
      },
      MX: {
        meta: {
          method: 'static'
        }
      },
      searches: false
    }
  },
  methods: {
    changeToggle (name, value = true, params = { type: 'touch' }) {
      if (params.type === 'mouse' && this.$store.state.breakpoint.screen.widthMinCalc <= 1200) {
        return
      }
      this.hover[name] = value
    },
    getFlag (name) {
      return getFlag(name)
    },
    getSearches () {
      if (process.client) {
        const data = window.localStorage.getItem('searches')
        if (data) {
          let parsed = JSON.parse(data)
          parsed.reverse()
          if (parsed && parsed.length) {
            parsed = parsed.filter((i) => {
              return dayjs(i.start_date).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')
            })
          }

          this.searches = parsed.length ? parsed : false
          return !!this.searches
        }
      }
      return false
    }
  },
  computed: {
    ...mapState({
      staticReviews: 'staticReviews',
      windowBreakpoint: 'breakpoint'
    }),
    ...mapGetters({
      locale: 'lang/locale',
      check: 'auth/user'
    }),
    checkSearches () {
      this.getSearches()
      return !!this.searches
    },
    getSliderSettings: app => (name) => {
      let isSlider = app.$store.state.breakpoint.screen.widthMinCalc <= 1024
      if (name === 'sailing-holidays') {
        isSlider = true
      }
      const SETTINGS = {
        'popular-destination': {
          isSlider: true,
          settings: {
            arrows: false,
            slidesToShow: 1,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        },
        'popular-destination-bnr': {
          isSlider: true,
          settings: {
            arrows: false,
            slidesToShow: 1,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  dots: true,
                  arrows: false
                }
              }
            ]
          }
        },
        'sailing-holidays': {
          settings: {
            slidesToShow: 1,
            arrows: false,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 3,
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        },
        'guests-say': {
          isSlider: true,
          settings: {
            arrows: false,
            slidesToShow: 1,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  arrows: false,
                  dots: true
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  arrows: false,
                  dots: true
                }
              },
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        },
        'discover-flotilla': {
          isSlider: true,
          settings: {
            arrows: false,
            slidesToShow: 1,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  arrows: false
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        },
        'best-rated': {
          settings: {
            slidesToShow: 1,
            arrows: false,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  dots: true
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        },
        'about-tubber': {
          settings: {
            slidesToShow: 1,
            arrows: false,
            responsive: [
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  arrows: false,
                  dots: true
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  dots: true,
                  arrows: false
                }
              },
              {
                breakpoint: 1024,
                settings: {
                  dots: false,
                  arrows: true
                }
              }
            ]
          }
        }
      }
      return {
        body: {
          isSlider,
          settings: {
            type: 'slider',
            gaps: {
              value: 24
            }
          },
          ...SETTINGS[name]
        }
      }
    }
  },
  jsonld () {
    const reviewListItems = this.staticReviews.map((review) => {
      return {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: 'Tubber',
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: review.rating,
            bestRating: 5,
            worstRating: 1
          },
          name: this.$t(review.title),
          author: {
            '@type': 'Person',
            name: this.$t(review.user_name)
          },
          reviewBody: this.$t(review.review_text)
        }
      }
    })
    return {
      name: 'Tubber',
      '@context': 'http://schema.org',
      '@type': 'ReviewList',
      itemListElement: reviewListItems
    }
  }
}
