import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15bc05f7&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=15bc05f7&prod&lang=scss"
import style1 from "./index.vue?vue&type=style&index=1&id=15bc05f7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15bc05f7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default,VImg: require('/var/www/tubber-new/components/global/v/img.vue').default,VButton: require('/var/www/tubber-new/components/global/v/button.vue').default})
